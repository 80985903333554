$white: rgb(255, 255, 255);
$grey: rgba(0, 0, 0, 0.54);
$greyBg: rgb(250, 249, 249);
$productLike: rgb(216, 216, 216);
$productBg: rgba(179, 181, 183, 0.2);
$primary: rgb(0, 129, 223);
$secondary: rgb(250, 100, 0);
$tertiary: rgb(245, 0, 87);
$greySearch: rgb(138, 138, 142);
$greyCheckbox: rgb(151, 151, 151);
$filterHead: rgba(0, 0, 0, 0.87);
$filterText: rgb(79, 79, 79);
$tableRowBackground: rgba(33, 150, 243, 0.05);
$red: rgb(212, 8, 32);
$redLight: rgb(221, 60, 79);
$buttonDefault: rgb(224, 224, 224);
$uncovered: rgb(249, 217, 217);
$partialy: rgb(219, 237, 250);
$covered: rgb(228, 253, 230);
$processing: rgb(245, 124, 0);
$partialySend: rgb(245, 125, 2);
$redButton: rgb(211, 47, 47);
$redButtonTransparent: rgba(211, 47, 47, 0.2);
$pharmacyGreen: rgb(76, 175, 80);
$pharmacyGreenTransparent: rgba(76, 175, 80, 0.19);
//$backdrop: rgba(98, 98, 98, 0.8);
