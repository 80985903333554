@mixin flex($align, $justify, $flexDirection) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  @if $flexDirection {
    flex-direction: $flexDirection;
  }
}

@mixin productQuantity($padding, $height, $separate) {
  .productQuantity {
    display: flex;
    @if $separate == true {
      margin-right: 30px;
    }
    .MuiButtonBase-root {
      background-color: $primary;
      color: $white;
      border: none;
      padding: $padding;
      min-width: 45px;
    }
    .quantity {
      background-color: $white;
      width: 70px;
      height: $height;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    &.rabatButton {
      button {
        background-color: $buttonDefault;
      }
      button.active {
        background-color: $secondary;
      }      
    }
  }
}
@mixin productToCart($width, $height, $paddingButton) {
  .productToCart {
    background-color: $primary;
    border-radius: 4px;
    width: $width;
    height: $height;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @if $paddingButton {
      padding: 6px 10px;
    }

    img {
      width: 20px;
      height: 20px;
    }

    svg {
      width: 19px;
    }
  }
}

@mixin smallCampaignBanner($width) {
  @if $width == true {
    width: 380px;
  } @else if $width == false {
    width: 430px;
  }
  height: 145px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px 0 0 15px;
  overflow: hidden;
  .content {
    z-index: 2;
    h5 {
      font-size: 1.0625rem;
      color: $white;
      letter-spacing: -0.46px;
      line-height: 1.3;
      padding-bottom: 5px;
    }
    p {
      font-size: 0.75rem;
      color: $white;
      line-height: 1.2;
    }
    button {
      color: $white;
      border-color: $white;
      align-self: flex-start;
      margin-top: 30px;
      padding: 2px 5px;
    }
  }

  img {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    z-index: -1;
    &.placeholder {
      z-index: 1;
    }
  }
}
