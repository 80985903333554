// imports
@import "fonts.scss";
@import "colors.scss";
@import "reset.scss";
@import "mixins.scss";

// global styles
* {
  box-sizing: border-box;
}
html {
  height: 100%;
}
a {
  text-decoration: none;
  color: inherit;
}

ul {
  li {
    list-style-type: none;
  }
}

p {
  &.MuiTypography-body1 {
    line-height: 1.375rem;
  }
  &.semiBold {
    font-weight: 500;
  }
}

// App styles
body {
  font-family: "Roboto", sans-serif;
  min-height: 100%;
  height: auto;
  width: 100%;
  background-color: $greyBg;
  display: flex;
  justify-content: center;

  // Root
  #root {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    min-width: 1440px;
    margin: 0 auto;
    transform-origin: 50% 0;

    @media (min-width: 768px) {
      transform: scale(0.45);
      width: 1440px;
    }
    @media (min-width: 1000px) {
      transform: scale(0.55);
    }
    @media (min-width: 1100px) {
      transform: scale(0.6);
    }
    @media (min-width: 1200px) {
      transform: scale(0.65);
    }
    @media (min-width: 1300px) {
      transform: scale(0.75);
    }
    @media (min-width: 1440px) {
      transform: scale(1);
    }
  }

  // Login Page
  &.Login {
    height: 100%;

    &::before {
      content: "";
      position: absolute;
      background-image: url("../assets/images/best-drugstore-bath-and-beauty.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      filter: blur(7px);
      width: 100%;
      height: 100%;
      z-index: -2;
    }

    &::after {
      content: "";
      background-color: $white;
      position: absolute;
      width: 100%;
      height: auto;
      opacity: 0.68;
      z-index: -1;
    }
    .container {
      background-color: rgba(0, 0, 0, 0);
      .content {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .SignIn {
          width: 330px;
          max-height: 370px;
          background-color: $white;
          text-align: center;

          h5 {
            color: $grey;
          }
          .MuiButton-containedPrimary {
            background-color: $primary;
          }
          .MuiFormLabel-root.Mui-focused {
            color: $primary;
          }
          .MuiInput-underline:after {
            border-bottom-color: $primary;
          }
        }
      }
    }
  }

  // Global styles
  .container {
    max-width: 1440px;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 57px;
    padding-right: 57px;
  }

  .separate {
    padding-bottom: 30px;
  }

  .line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &.bottom {
      align-items: flex-end;
    }
  }

  // Header
  .header {
    height: 97px;
    width: 100%;
    max-width: 1440px;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    padding-left: 24px;
    padding-right: 24px;

    &.logged {
      background-color: #faf9f9;

      .menu {
        display: flex;
        align-items: center;
        margin-right: 36px;

        li {
          padding-left: 15px;
          .MuiButton-outlinedPrimary {
            color: $primary;
            border-color: $primary;
            min-width: 36px;
            text-transform: none;

            .iconUser {
              margin-left: 10px;
            }
          }
          .MuiBadge-colorPrimary {
            background-color: $primary;
          }
          svg {
            width: 24px;
            height: 24px;
          }
          &.divide {
            border-right: 1px solid $primary;
            padding-right: 15px;
          }
        }
      }
    }
  }
  // Header dropdown menu
  #customized-menu {
    .menuItem {
      &:focus {
        background-color: $primary;
        .MuiListItemIcon-root,
        .MuiListItemText-primary {
          color: white;
        }
      }
    }
    .MuiMenu-paper {
      border: 1px solid #d3d4d5;
    }
    .MuiList-padding {
      padding: 0;
    }
    .MuiListItemIcon-root {
      justify-content: flex-end;
    }
  }

  /* Pages start */
  &.Info {
    .content {
      padding-top: 21px;
      display: flex;

      .left {
        width: 25%;
        .MuiListItem-root {
          border-radius: 5px;
          &.Mui-selected,
          &.Mui-selected:hover {
            background-color: $primary;
            color: $white;
          }
          .MuiListItemIcon-root {
            justify-content: flex-end;

            svg {
              color: white;
            }
          }
        }
      }
      .right {
        width: 75%;
        padding-left: 95px;
        .infoHeading {
          font-size: 2.25rem;
          padding: 8px 0 36px 0;
        }
        p {
          font-size: 1rem;
          line-height: 1.5;
          padding-bottom: 24px;
        }
      }
    }
  }

  // Homepage
  &.Homepage {
    .search {
      padding-top: 70px;
      padding-bottom: 65px;
    }
    .swiperContainer {
      padding-bottom: 80px;
      .swiperNext {
        right: -20px;
      }
      .swiperPrev {
        left: -20px;
      }
    }
    .campaignPage {
      @include flex(center, center, column);
      .campaignBlock {
        @include flex(center, space-between, row);
        flex-wrap: wrap;
        margin-bottom: 145px;

        .smallCampaignBanner {
          @include smallCampaignBanner(false);
          margin-bottom: 25px;
        }
      }
    }
    .manufacturerPage {
      @include flex(center, center, column);

      .headline {
        align-self: flex-start;
        font-size: 1.5rem;
        margin: 30px 0 40px;
      }
      .manufacturerBlock {
        @include flex(center, space-between, row);
        flex-wrap: wrap;
      }
      .pagination {
        margin: 5px 0 10px;
        align-self: flex-end;
      }
    }
  }

  // Product page
  &.Products {
    .productPage {
      display: flex;
      padding-bottom: 50px;

      .productMenu {
        width: 20%;
        h5 {
          padding-bottom: 30px;
        }
        .search {
          max-width: 250px;
          padding-top: 0;
          padding-bottom: 25px;
          a {
            right: 7px;
          }
          #Search {
            padding: 5px 30px 5px 0;
            margin-left: 5px;
          }
        }
      }
      .productList {
        width: 80%;
        display: flex;
        flex-direction: column;
        padding-left: 40px;

        .products {
          @include flex(center, flex-start, row);
          flex-wrap: wrap;

          .product {
            max-width: 230px;
            margin: 0 10px 25px 10px;
          }
        }
        .MuiPagination-root {
          margin-left: auto;
          margin-right: 0;
        }
      }
    }
  }

  // Detail page
  &.Detail {
    .productDetail {
      padding-bottom: 140px;
      .main {
        margin: 35px 0 30px;
        display: flex;
        .left {
          width: 50%;
          margin-right: 15px;
          .swiperContainer {
            padding-bottom: 45px;
            .swiperNext {
              right: 20px;
            }
            .swiperPrev {
              left: 20px;
            }
          }
          h6 {
            margin-bottom: 20px;
          }
        }
        .right {
          width: 50%;
          margin-left: 15px;
          .mb {
            margin-bottom: 5px;
          }
          .space {
            margin: 20px 0 30px;
          }
        }
      }
    }
  }

  // Kampane page
  &.Campaigns,
  &.Basket {
    .kampanePage,
    .kosikPage {
      display: flex;
      width: 100%;
      .left {
        width: 25%;
        padding-left: 10px;
        padding-right: 25px;

        .mbt {
          margin: 50px 0 20px 0;
        }

        .product {
          margin-bottom: 40px;
        }
      }
      .right {
        width: 75%;
        padding-left: 25px;
        .head {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          button {
            margin-right: 20px;
            &.productToCart {
              margin-right: 0;
              margin-left: auto;
              img {
                width: 20px;
              }
            }
          }
        }
        .informationLine {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
          margin: 20px 0 80px 0;
          button {
            border-color: $secondary;
            color: $secondary;

            &.primary {
              border-color: $primary;
              color: $primary;
            }
          }
        }
        .content {
          margin-bottom: 80px;
          &.rest {
            .line {
              margin-bottom: 30px;
            }
          }
        }
        .pagination {
          margin: 110px 0 50px auto;
        }
        .grandTotal {
          margin-bottom: 50px;
        }
      }
    }
  }

  // Objednavky
  &.Orders {
    .head {
      @include flex(center, space-between, $flexDirection: false);
      margin-top: 30px;
      .autocomplete {
        width: 360px;
      }
    }
    .content {
      margin: 30px 0 30px;
      @include flex(normal, center, column);
      &:last-child {
        margin: 30px 0 80px;
      }
      h6 {
        margin-bottom: 30px;
      }
      .objednavky {
        @include flex(normal, normal, column);
        .objednavkyHead {
          @include flex(normal, normal, row);
        }
      }
    }
    .pagination {
      align-self: flex-end;
      margin-top: 30px;
    }
  }

  // Uzivatel
  &.User {
    .container {
      position: relative;
    }
    h5 {
      margin-bottom: 10px;
    }
    .addPharmacy {
      width: auto;
      display: flex;
      /* align-items: center;
      justify-content: flex-end; */
      position: absolute;
      top: 47px;
      right: 0;
      z-index: 10;
      padding-right: 57px;
      button {
        text-transform: unset;
        &:first-child {
          margin-right: 15px;
        }
      }
    }

    .tabHeader {
      margin-bottom: 20px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
    .tabBodyUser,
    .tabBodyPharmacy {
      margin-bottom: 150px;
    }
    .tabBodyPharmacy {
      &.noShadow {
        .MuiPaper-root {
          background-color: transparent;
          box-shadow: none;
          overflow-x: visible;
        }
      }
      &.white {
        background-color: $white;
        border-radius: 4px;
      }
    }
    .user,
    .editPharmacy,
    .newPharmacy {
      padding-left: 25px;
      padding-right: 25px;
      h6 {
        padding-top: 25px;
        margin-bottom: 20px;
      }
      .editPharmacyHead {
        @include flex(flex-start, space-between, row);
        margin-bottom: 20px;
        padding-top: 25px;
        h6 {
          padding-top: 0;
          margin-bottom: 0;
          align-self: flex-end;
        }
      }
      .userForm,
      .newPharmacyForm {
        @include flex(center, space-between, column);
        padding-bottom: 25px;

        .formLine {
          @include flex(center, space-between, row);
          width: 100%;
          margin-bottom: 25px;
          .MuiFormControl-root {
            width: 48%;
          }
          &.formLineSingle {
            padding-bottom: 200px;
            .selectButton {
              width: 100%;
            }
          }
          .selectButton {
            @include flex(center, flex-end, $flexDirection: false);
            position: relative;
            width: 48%;
            svg {
              position: absolute;
              right: 20px;
              width: 28px;
              height: 28px;
              transform: rotate(0);
              transition: 0.2s all;
              &.selected {
                transition: 0.2s all;
                transform: rotate(180deg);
              }
            }
            .MuiFormControl-root {
              width: 100%;
            }
          }
        }
        button {
          align-self: flex-end;
        }
        .passwordLine {
          @include flex(center, space-between, row);
          width: 100%;
          margin-bottom: 15px;

          .MuiFormControl-root {
            width: 31%;
          }
        }
      }
      .preferred {
        fieldset {
          border-color: $pharmacyGreen;
        }
      }
    }
    .newPharmacyPage {
      display: flex;
      flex-direction: column;
      .newPharmacy {
        padding-top: 30px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .newPharmacyHead {
        margin-top: 50px;
        @include flex(flex-end, space-between, row);
        h5 {
          margin-bottom: 0;
        }
      }
      .goRight {
        align-self: flex-end;
        margin-bottom: 250px;
      }
    }
  }

  /* Pages end */

  /* Components start */
  //Campaigns
  .campaigns {
    max-width: 1440px;
    display: flex;
    flex-direction: row;
    width: 100%;

    .bigCampaign {
      width: 100%;
      max-width: 1060px;
      max-height: 435px;
      position: relative;
      padding: 32px 0 0 57px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .content {
        z-index: 2;

        h2 {
          color: $white;
          font-weight: 700;
          font-size: 2.25rem;
          letter-spacing: 1px;
          padding-bottom: 9px;
        }

        p {
          color: $white;
          letter-spacing: 0.38px;
          padding-bottom: 1rem;
        }

        button {
          border-color: $white;
          color: $white;
          padding: 3px 7px;
          align-self: flex-start;
          span {
            font-size: 0.8125rem;
          }
        }

        .lowerText {
          display: flex;
          justify-content: flex-end;
          height: 100%;
          align-items: flex-start;
          flex-direction: column;
          padding-bottom: 1rem;
          .lowerTextHeadline {
            font-weight: 500;
            font-size: 1.5rem;
            color: $white;
            padding-bottom: 8px;
          }
          p {
            max-width: 52%;
            font-size: 1.125rem;
            letter-spacing: -0.29px;
          }
        }
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: auto;

        &.placeholder {
          z-index: 1;
        }
      }
    }
    .smallCampaign {
      max-height: 435px;
      max-width: 380px;
      .smallCampaignBanner {
        @include smallCampaignBanner(true);
      }
    }
  }
  // Promo
  .promoBlock {
    display: flex;
    justify-content: space-between;
    .promo {
      max-width: 422px;
      max-height: 211px;
      display: flex;
      flex-direction: column;

      .promoImage {
        max-height: 173px;
        width: 100%;
      }
      .promoText {
        padding-top: 0.2rem;
        display: flex;
        justify-content: space-between;

        p,
        a {
          font-size: 1.125rem;
          line-height: 1.9375rem;
        }

        a {
          color: $primary;
        }
      }
    }
  }
  // Search
  .search {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 50px;

    .searchHead {
      display: inline-flex;
      justify-content: space-between;
      padding: 0 0 20px 10px;
      h5 {
        font-size: 1.5rem;
        line-height: 1.375;
      }
    }

    .searchField {
      border-radius: 102px;
      border: 1px solid $greySearch;
      position: relative;
      width: 100%;

      .searchIcon {
        height: 100%;
        position: absolute;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: $greySearch;
        right: 20px;
        cursor: pointer;
      }
      .MuiInputBase-root {
        width: 100%;
        padding-right: 40px;
        input:-internal-autofill-selected {
          background-color: transparent !important;
        }
        #Search {
          padding: 15px 0 15px 20px;
          color: $greySearch;
          border-color: $greySearch;
        }
        #SearchManufacturer {
          padding: 5px 0 5px 20px;
          color: $greySearch;
          border-color: $greySearch;
        }
      }
    }
    .mostVisited {
      display: flex;
      justify-content: space-between;
      padding: 10px 30px 0 30px;
      ul {
        display: inline-flex;
        li {
          padding-right: 15px;
          a {
            color: $primary;
            text-decoration: underline;
          }
        }
      }
    }
  }
  // Product
  .product {
    max-width: 250px;
    //max-height: 350px;
    width: 100%;
    height: 350px;
    position: relative;
    .MuiPaper-root {
      width: inherit;
      height: inherit;
    }
    .productHeader {
      padding: 11px;
      height: 36px;
      .productBadge {
        color: $white;
        height: 24px;

        &.campaign {
          background-color: $secondary;
        }
        &.new {
          background-color: $tertiary;
        }
      }
      .MuiCardHeader-action {
        margin: 0;
        .productLike {
          color: $productLike;
          font-size: 1.5rem;
          letter-spacing: 0.1px;
          cursor: pointer;

          &.productSaved {
            color: red;
          }
        }
      }
    }
    .productImage {
      width: 250px;
      height: 160px;
    }
    .productBody {
      padding: 11px 11px 7px;
      max-height: 108px;
      height: 100%;
      .productName {
        font-size: 1.125rem;
        line-height: 1.2;
        height: 45px;
        overflow: hidden;
      }
      .productOverview {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding-top: 12px;
        h5 {
          font-size: 0.75rem;
          letter-spacing: 0.7px;
        }
        p {
          font-size: 0.5rem;
        }
        .productPrice {
          font-size: 1.875rem;
        }
      }
    }
    .productFooter {
      height: 43px;
      justify-content: space-between;
      background-color: $productBg;
      margin-left: 3px;
      margin-right: 3px;
      border-radius: 3px;

      @include productQuantity(3px 18px, 31px, false);

      @include productToCart(38px, 30px, false);
    }
  }
  // Products with Swiper
  .products {
    .productsHeadline {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h5 {
        font-size: 1.5rem;
      }
      a {
        background-color: $primary;
        color: $white;
      }
    }
  }
  //Swiper
  .swiperContainer {
    position: relative;
    // Swiper on Homepage
    .swiper {
      padding: 15px 0;
      .swiper-wrapper {
        .swiper-slide {
          margin: 3px;
          width: 250px !important;
        }
      }
    }
    // Swiper on Detail page
    .swiperDetail {
      max-width: 650px;
      max-height: 540px;
      background-color: $white;
    }
    // Navigation
    .swiperNavigation {
      position: absolute;
      bottom: 60%;
      left: 0;
      width: 100%;

      .swiperNext,
      .swiperPrev {
        position: absolute;
        z-index: 100;
        background-color: $primary;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
        svg {
          color: $white;
        }
      }
    }
  }
  // Manufacturers
  .manufacturers {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    .manufacturersHead {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 40px;
      h5 {
        font-size: 1.5rem;
      }
      button {
        background-color: $primary;
        color: $white;
      }
    }

    .manufacturersBlock {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
    }
  }
  .manufacturer {
    max-width: 320px;
    max-height: 210px;
    margin-bottom: 30px;

    img {
      width: 320px;
      max-height: 200px;
    }
    .manufacturersLower {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      p,
      a {
        font-size: 1.125rem;
      }
      a {
        font-weight: 100;
      }
    }
  }

  // Button
  .button {
    &.orange {
      background-color: $processing;
      color: $white;
    }
    &.red {
      background-color: $redButton;
      color: $white;
    }
    &.white {
      background-color: $white;
      color: $filterHead;
    }
    &.rounded {
      border-radius: 50%;
      padding: 10px;
      min-width: auto;
    }
    &.regular {
      font-weight: 400;
    }
    &.icon {
      svg {
        width: 20px;
        height: 20px;
      }
      &.iconSeparate {
        svg {
          margin-right: 5px;
        }
      }
    }
  }

  // Filters
  .filter {
    margin-bottom: 50px;

    &.filterManufacturer {
      height: 220px;
      overflow-y: scroll;
      flex-wrap: nowrap;
    }
    p {
      font-size: 1rem;
      color: $filterHead;
    }
    .PrivateSwitchBase-root-1 {
      padding: 3px 10px;
    }
    span.MuiTypography-root {
      font-size: 0.75rem;
      color: $filterText;
    }
  }
  .filterSelected {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    p {
      padding-right: 20px;
    }
    .MuiChip-root {
      margin-right: 15px;
    }
    .filterButton {
      margin-right: 0;
      margin-left: auto;

      &.close {
        border-color: $secondary;
        color: $secondary;
      }
    }
  }
  #simple-popover {
    margin-top: 10px;
    .MuiPopover-paper {
      width: 330px;
      height: 230px;
      text-align: center;
      padding: 20px 20px;
      p {
        font-size: 1.5rem;
        color: $grey;
        margin-bottom: 20px;
      }
      .MuiFormControl-root {
        margin-bottom: 40px;
      }
      .filterName {
        font-size: 1rem;
        color: $filterHead;
        margin: 35px 0 50px 0;
      }
      .filterButtonGroup {
        display: inline-flex;
        width: 100%;
        justify-content: space-around;
        button {
          padding: 6px 42px;
          &.close {
            background-color: $secondary;
            color: $white;
          }
        }
        &.pharmacyFilterGroup {
          button {
            padding: 6px 16px;
            &:first-child {
              padding: 6px 42px;
            }
          }
        }
      }
    }
  }
  .filterSpecial {
    .MuiListItem-root {
      border-radius: 5px;
      &.Mui-selected {
        background-color: $primary;
        color: $white;
      }
      .MuiListItemIcon-root {
        justify-content: flex-end;
        color: $white;
      }
      .MuiTypography-body1 {
        font-size: 0.875rem;
      }
    }
  }
  .rangeSlider {
    margin-bottom: 25px;
    .price {
      color: $filterHead;
    }
    .priceValue {
      color: $primary;
      font-size: 1.125rem;
    }
    .rangeSliderHead {
      display: flex;
      justify-content: space-between;
      padding-bottom: 50px;
    }
  }
  .filterProductDetail {
    display: inline-flex;
    align-items: center;
    margin: 30px 0 0 0;

    h6 {
      margin-right: 20px;
    }
    .MuiChip-root {
      margin-right: 10px;
    }
  }
  // toBasket
  .toBasket {
    border: 1px solid $primary;
    border-radius: 4px;
    display: flex;
    align-items: center;
    width: 650px;
    height: 90px;
    padding: 0 20px;
    margin: 30px 0;

    .price {
      margin-left: 0;
      margin-right: auto;
      color: $primary;
      h6 {
        font-size: 1.3125rem;
      }
    }

    @include productQuantity(8px 10px, 39px, true);

    @include productToCart(180px, 40px, true);
  }
  // Campaign Action section
  .campaignAction {
    background-color: $secondary;
    border-radius: 4px;
    width: 650px;
    height: 90px;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    h6 {
      font-size: 1.3125rem;
    }
    button {
      background-color: $white;
      color: $secondary;
    }
  }
  // Specification
  .specification {
    margin-top: 50px;
    h6 {
      margin-bottom: 10px;
    }
    .table {
      tr:nth-of-type(odd) {
        background-color: $tableRowBackground;
      }
      .MuiTableRow-root {
        th.MuiTableCell-root {
          width: 20%;
        }
        .MuiTableCell-root {
          white-space: pre;
        }
      }
    }
  }
  // OtherProducts
  .otherProducts {
    display: flex;
    flex-direction: column;
    .h5 {
      margin-bottom: 35px;
    }
    .product {
      margin-right: 20px;
    }
  }
  // Sale
  .sale {
    .progressHead {
      display: flex;
      align-items: center;
      text-align: center;

      .progressText {
        display: inline-flex;
        align-items: center;
        color: $tertiary;

        &.point {
          color: #bdbdbd;
          margin-left: 30%;
          position: relative;

          &::after {
            content: "";
            width: 6px;
            height: 28px;
            background-color: $tertiary;
            border-radius: 4px;
            position: absolute;
            top: 36px;
            z-index: 10;
            left: 0;
          }
        }
        &.pointSecond {
          margin-left: 38%;
          &::after {
            left: unset;
            right: 1px;
          }
        }
        &.withIcon {
          margin-left: 27%;
        }
        &.pointSecond.withIcon {
          margin-left: 34%;
          &::after {
            right: -4px;
          }
        }

        svg {
          width: 21px;
          height: 21px;
          margin-left: 10px;
        }
      }
    }
    .progressBorder {
      border: 2px solid $tertiary;
      padding: 1px;
      border-radius: 4px;
      .progress {
        height: 30px;
        background-color: white;
        border-radius: 4px;
        .MuiLinearProgress-bar {
          border-radius: 2px 0 0 2px;
          background-color: $tertiary;
          &::after {
            content: "";
            width: 25px;
            height: 25px;
            background: $tertiary;
            transform: rotate(45deg);
            display: block;
            position: absolute;
            top: 3px;
            right: -13px;
            z-index: -1;
            border-radius: 0 8px 0 0;
          }
        }
      }
    }
  }
  // Select Supplier
  .selectSupplier {
    .MuiInputBase-root {
      color: $filterHead;
      img {
        display: none;
      }
      svg {
        display: none;
      }
    }
  }
  .MuiMenu-paper {
    //margin-top: 46px;
    .MuiList-padding {
      padding: 0;
    }
    .MuiListItem-root.Mui-selected.selected,
    .MuiListItem-root.Mui-selected.selected:hover {
      background-color: $primary;
      color: $white;
    }
    .MuiListItem-gutters {
      padding-left: 10px;
      padding-right: 10px;
    }
    svg {
      margin-right: 0;
      margin-left: auto;
    }
  }

  // Kampane products
  .kampaneProducts,
  .kosikProducts {
    display: flex;
    flex-direction: column;
    .kosikProductsHead {
      display: inline-flex;
      padding-bottom: 5px;
    }
    .productToCart {
      margin-right: 0;
      margin-left: auto;
      margin-top: 25px;
      img {
        width: 20px;
      }
    }
  }

  // ProductListItem
  .productListItem {
    box-shadow: none;
    background-color: transparent;
    overflow-x: visible;
    table {
      thead {
        tr {
          th {
            border-bottom: none;
          }
        }
      }
      tbody {
        tr {
          height: 65px;
          background-color: $white;
          box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 1px 3px 0px rgba(0, 0, 0, 0.12);
          border-radius: 4px;
          td {
            border-bottom: none;
            border-radius: 4px;
            padding: 16px 10px;

            &:nth-of-type(2) {
              width: 60%;
            }
          }
        }
      }
    }
    .image {
      width: 90px;
      height: 50px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    @include productQuantity(6px 18px, 36px, false);

    .rabat {
      border: 1px solid black;
      height: 36px;
      width: 74px;
      border-radius: 4px;
      @include flex(center, center, $flexDirection: false);
    }

    .erase {
      padding: 11px 16px;
      min-width: 36px;
    }

    .red {
      color: $red;
    }
  }
  //GrandTotal
  .grandTotal {
    h6 {
      font-size: 1.875rem;
      margin-bottom: 40px;
    }
    .buttonLine {
      @include flex(center, space-between, $flexDirection: false);
    }
  }
  // Order
  .order {
    /*  @include flex(center, normal, column);
    min-height: 65px; */
    background-color: transparent;
    margin-bottom: 10px;
    box-shadow: none;
    overflow: visible;

    .orderItem {
      background-color: $white;
      &.uncovered {
        background-color: $uncovered;
      }
      &.covered {
        background-color: $covered;
      }
      &.partialy {
        background-color: $partialy;
      }
      .processing {
        color: $processing;
      }
    }

    table {
      border-collapse: separate !important;
      thead {
        tr {
          th {
            border-bottom: 0;
          }
        }
      }
      tbody {
        tr {
          box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 1px 3px 0px rgba(0, 0, 0, 0.12);
          border-radius: 4px;
          td {
            border-bottom: 0;

            &:first-child {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }
            &:last-child {
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            }
          }
        }
      }
    }
    .collapseItem {
      td {
        padding-left: 0;
        padding-right: 0;
      }
      .collapse {
        min-height: 52px;
        width: 100%;
        padding-top: 40px;
        padding-bottom: 30px;
        background-color: $white;
        .orderProduct {
          @include flex(center, flex-start, row);
          padding: 20px 0;
          img {
            width: auto;
            max-height: 50px;
            margin-left: 20px;
          }
          p {
            margin-left: 30px;

            &.notSend {
              color: $red;

              &.light {
                color: $redLight;
              }
            }
            &.partialySend {
              color: $partialySend;
              &.darker {
                color: $processing;
              }
            }

            &:nth-child(3) {
              margin-left: 140px;
            }
            &:nth-child(4) {
              margin-left: 40px;
            }
            &:nth-child(5) {
              margin-left: 90px;
            }
          }
          a {
            margin-left: auto;
            margin-right: 20px;
          }
        }
      }
    }
  }

  // Pharmacy
  .pharmacyItem {
    margin-bottom: 10px;

    table {
      height: 65px;
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      border-collapse: separate;
      background-color: $white;

      &.selectedItem {
        border: 2px solid $pharmacyGreen;
        background-color: $pharmacyGreenTransparent;
      }
      &.deletedItem {
        border: 2px solid $redButton;
        background-color: $redButtonTransparent;
      }

      td {
        border-bottom: none;
        &:first-child {
          width: 12%;
        }
        &:nth-child(2) {
          width: 30%;
        }
        &:nth-child(3) {
          width: 35%;
        }
        &:nth-child(4) {
          padding-right: 0;
        }
        &:nth-child(5) {
          padding-left: 0;
        }

        &.access {
          color: $primary;
        }
        &.selected {
          color: $pharmacyGreen;
          width: 12.3%;
          text-align: center;
          font-weight: 500;
          padding-left: 16px;
        }
        &.deleted {
          color: $tertiary;
          font-weight: 500;
          padding-right: 22px;
        }
      }
    }
  }

  /* Components end */

  // Footer
  .footer {
    background-color: $white;
    width: 100%;
    height: 281px;
    color: $grey;
    .container {
      padding-top: 70px;
      padding-left: 57px;
      padding-right: 57px;
      display: flex;
      flex-direction: column;
      background-color: $white;
      .footerInner {
        display: flex;
        align-items: center;
        .footerLogo {
          width: 25%;
        }
        .footerNav {
          width: 75%;
          nav {
            ul {
              display: flex;
              justify-content: flex-end;
              li {
                list-style-type: none;
                padding-left: 60px;
                text-transform: uppercase;
              }
            }
          }
        }
      }

      .footerCopy {
        width: 100%;
        align-items: flex-end;
        flex-direction: row;
        justify-content: flex-end;
        position: relative;
        height: auto;
        display: flex;
        top: 70px;
      }
    }
  }
}
